<template>
    <div></div>
</template>
<script>
import {loginByPhoneNumber} from "@/service/user";
import {setToken, setName, setLogo, setcompany} from "@/service/lib/localToken";
import Cookies from "js-cookie";

export default {
    name: "LoginPhone",
    data() {
        return {
            phoneNumber: ''
        };
    },
    mounted() {
        this.login();
    },
    methods: {
        login() {
            const searchParams = this.$route.query;
            const phoneNumber = searchParams.phoneNumber;
            if (phoneNumber) {
                const _this = this;
                const param = {
                    phoneNumber: phoneNumber
                };
                _this.loading = true;
                loginByPhoneNumber(param).then(res => {
                    const {token, user} = res.data;
                    setToken(token);
                    Cookies.set('companyId', user.company_id);
                    setName(res.data.user.company.name);
                    setLogo(res.data.user.company.logo);
                    setcompany(res.data.user.company);
                    _this.loading = false;
                    _this.$router.push({path: _this.redirect || '/', query: _this.otherQuery}, () => {
                    });
                });
            } else {
                this.$message.error("手机号为空");
                this.$router.push({path: '/login'}, () => {
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
